import styled from "styled-components";

const ErrorComponent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid #d64541;
  background-color: white;
`;

export const StyledError = styled.div`
  padding: 24px;
  color: red;
  border: 2px solid red;
  background: #ffc4c4;
  margin-top: 24px;
  border-radius: 8px;
`;

const Error = () => {
  return (
    <ErrorComponent>Something went wrong ... please try again</ErrorComponent>
  );
};

export default Error;
