import { useDialogState as ReakitUseDialogState } from "reakit/Dialog";

interface UseDialogStateProps {
  baseId?: string;
  visible?: boolean;
}

interface UseDialogStateReturn {
  baseId: string;
  visible: boolean;
  hide: () => void;
  toggle: () => void;
}

export const useDialogState = (props?: UseDialogStateProps) => {
  return ReakitUseDialogState({
    animated: true,
    ...props,
  }) as UseDialogStateReturn;
};
