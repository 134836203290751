import styled from "styled-components";
import Header from "../../components/Header";
import { Container, Grid, GridColumn, Row } from "../../shared/Grid";
import { policy } from "./policy";

const Paragraph = styled.p`
  font-size: 20px;
  line-height: 1.5;
  margin: 40px 0;
`;

const Title = styled.h1`
  margin-top: 40px;
`;

const PrivacyPolicy = () => (
  <>
    <Header />
    <Container variant="full-width">
      <Grid>
        <GridColumn mobile={2}>
          <Row>
            <Title>Privacy policy</Title>
            <Paragraph>{policy}</Paragraph>
          </Row>
        </GridColumn>
      </Grid>
    </Container>
  </>
);

export default PrivacyPolicy;
