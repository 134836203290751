import axios from "axios";
import styled, { css } from "styled-components";
import { JobsProp, StatusType } from "../../../../../../typings";
import { GridColumn } from "../../../../../../shared/Grid";
import {
  ButtonWrapper,
  Heading,
  Paragraph,
} from "../../../../../../shared/Styles";
import StatusBadge from "../../../../../../shared/StatusBadge";
import Button from "../../../../../../shared/Button";
import useAuthToken from "../../../../../../utils/api/useAuthToken";

const PDFDownload = styled.button`
  padding: 15px 24px;
  display: block;
  border: none;
  text-align: center;
  font-size: 16px;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  font-weight: 600;
`;

const ButtonVariant = styled.button`
  display: block;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  transition: border-color 0.2s ease, background-color 0.2s ease,
    color 0.2s ease;
  padding: 15px 24px;
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 8px;
  &:hover,
  &:active {
    background-color: ${({ theme: { colors } }) => colors.primary};
    color: white;
  }
`;

const JobsParagraph = styled(Paragraph)`
  display: block;
  margin: 0;
`;

const JobWrapper = styled.div`
  ${({ theme: { colors, spacing } }) => css`
    border: 2px solid ${colors.backgroundDark};
    padding: ${spacing.s3};
    box-shadow: 0 10px 21px #e7eeef;
    background: white;
    border-radius: 8px;
    height: 100%;
  `};
`;

const Seperator = styled.div`
  display: block;
  margin-bottom: ${({ theme: { spacing } }) => spacing.s2};
`;

interface JobsType {
  jobsData: JobsProp[];
  editJobModal: any;
  removeJobModal: any;
  setSelectedJobId: Function;
  setStatus: Function;
}

const Jobs = ({
  jobsData,
  removeJobModal,
  editJobModal,
  setSelectedJobId,
  setStatus,
}: JobsType) => {
  const { headers } = useAuthToken();

  function handleStatusChange(status: StatusType) {
    return status === "Unallocated"
      ? "InProgress"
      : status === "InProgress"
      ? "Completed"
      : "Confirmed";
  }

  const handleJobVariantChange = (status: StatusType, id: number) => {
    axios
      .put(
        `https://api.punitruckhire.nz/Jobs/${id}/updatestatus/${handleStatusChange(
          status
        )}`,
        {},
        { headers }
      )
      .then(() => {
        setStatus("LOADING");
        setSelectedJobId(0);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const handleStatusName = (status: StatusType) => {
    const result = handleStatusChange(status).replace(/([A-Z])/g, " $1");

    return `Change job status to ${
      result.charAt(0).toLowerCase() + result.slice(1)
    }`;
  };

  const handlePDFDownload = async (id: number) => {
    fetch(`https://api.punitruckhire.nz/Jobs/${id}/generatepdf`, {
      method: "POST",
      headers,
    })
      .then((response: any) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link && link.parentNode && link.parentNode.removeChild(link);
      });
  };

  const convertPascalCase = (string: string) => {
    const result = string.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  return (
    <>
      {jobsData &&
        jobsData.map((job: JobsProp) => {
          const startDate = new Date(`${job.startDate}.000Z`);
          const endDate = job.completedDate
            ? new Date(`${job.completedDate}.000Z`)
            : null;

          return (
            <GridColumn mobile={2} desktop={6}>
              <JobWrapper key={job.id}>
                <Heading>Job ID:</Heading>
                <JobsParagraph>
                  <p>{job.id}</p>
                </JobsParagraph>
                <Seperator />
                <Heading>Client Name:</Heading>
                <JobsParagraph>{job.clientName}</JobsParagraph>
                <Seperator />

                <Heading>Type:</Heading>
                <JobsParagraph>{convertPascalCase(job.type)}</JobsParagraph>
                <Seperator />

                <Heading>Pick Up Address:</Heading>
                <JobsParagraph>{job.pickUpAddress}</JobsParagraph>
                <Seperator />

                <Heading>Delivery Up Address:</Heading>
                <JobsParagraph>{job.deliveryAddress}</JobsParagraph>
                <Seperator />

                <Heading>Truck Type:</Heading>
                <JobsParagraph>
                  {convertPascalCase(job.truckType)}
                </JobsParagraph>
                <Seperator />

                <Heading>Driver Name:</Heading>
                <JobsParagraph>{job.driverName}</JobsParagraph>
                <Seperator />

                <Heading>Status:</Heading>
                <StatusBadge status={job.status}>{job.status}</StatusBadge>
                <Seperator />

                <Heading>Travel Time</Heading>
                <JobsParagraph>{job.travelTime}</JobsParagraph>
                <Seperator />

                <Heading>Break Time</Heading>
                <JobsParagraph>{job.breakTime}</JobsParagraph>
                <Seperator />

                <Heading>Start Date</Heading>
                <JobsParagraph>
                  {startDate.toLocaleString("en-NZ")}
                </JobsParagraph>
                <Seperator />

                <Heading>End Date</Heading>
                <JobsParagraph>
                  {endDate?.toLocaleString("en-NZ")}
                </JobsParagraph>
                <Seperator />

                <Heading>Total time</Heading>
                <JobsParagraph>{job.chargeableTime}</JobsParagraph>
                <Seperator />

                {job.additionalDetails ? (
                  <>
                    <Heading>Additional Details:</Heading>
                    <JobsParagraph>{job.additionalDetails}</JobsParagraph>
                  </>
                ) : null}
                <Seperator />
                {job.status !== "Confirmed" && job.status !== "Unallocated" ? (
                  <ButtonVariant
                    onClick={() => {
                      handleJobVariantChange(job.status, job.id);
                    }}
                  >
                    {` ${handleStatusName(job.status)}`}
                  </ButtonVariant>
                ) : null}
                {job.status === "Confirmed" || job.status === "Completed" ? (
                  <PDFDownload onClick={() => handlePDFDownload(job.id)}>
                    Download PDF
                  </PDFDownload>
                ) : null}

                <ButtonWrapper>
                  <Button
                    variant="primary"
                    onClick={() => {
                      editJobModal.toggle();
                      setSelectedJobId(job.id);
                    }}
                    size="Small"
                  >
                    Edit job
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      removeJobModal.toggle();
                      setSelectedJobId(job.id);
                    }}
                    size="Small"
                  >
                    Delete job
                  </Button>
                </ButtonWrapper>
              </JobWrapper>
            </GridColumn>
          );
        })}
    </>
  );
};

export default Jobs;
