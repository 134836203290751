export const jobTypeOption = [
  { value: "OnSite", label: "On Site" },
  { value: "MultiSite", label: "Multi Site" },
];

export const truckTypeOptions = [
  { value: "SixWheeler", label: "Six Wheeler" },
  { value: "EightWheeler", label: "Eight Wheeler" },
  { value: "SixWheelerWithTrailer", label: "Six Wheeler With Trailer" },
  { value: "EightWheelerWithTrailer ", label: "Eight Wheeler With Trailer " },
];
export const truckTypeValues = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4 ", label: "4" },
];
