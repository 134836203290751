export const ModifiedOptions = (data: any) => {
  return data.map(({ name: value, ...rest }: any) => ({
    value,
    label: value,
    ...rest,
  }));
};

export const MultiplyArray = (arr: any, length: number) =>
  Array.from({ length }, () => arr).flat();

export const checkAdminRole = (user: any) =>
  user && user["https://punitruckhire.nz/roles"][0] === "Admin";
