import styled from "styled-components";
import {
  Dialog as ReakitDialog,
  DialogBackdrop as ReakitDialogBackdrop,
} from "reakit/Dialog";
import React from "react";

interface ModalProps {
  baseId: string;
  visible: boolean;
  hide: () => void;
  tabIndex?: number;
}

interface OverlayProps {
  baseId: string;
  hide: () => void;
  tabIndex?: number;
}

const StyledModal = styled(ReakitDialog)<ModalProps>`
  background: #fff;
  border-radius: 8px;
  z-index: 1000;
  padding: 24px;
  max-height: 700px;
  overflow: auto;
  width: 500px;
  opacity: 0;
  outline: none;
  overflow: auto;
  transform: translateY(40%);
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;

  &[data-enter] {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Overlay = styled(ReakitDialogBackdrop)<OverlayProps>`
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-enter] {
    opacity: 1;
  }
`;

const Modal = (props: React.PropsWithChildren<ModalProps>) => {
  const { children, ...modalProps } = props;

  return (
    <Overlay {...modalProps}>
      <StyledModal
        hideOnEsc
        hideOnClickOutside
        preventBodyScroll
        {...modalProps}
      >
        {children}
      </StyledModal>
    </Overlay>
  );
};

export default Modal;
