import { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { StatusType } from "../../typings";

interface StatusBadgeProps {
  status: StatusType;
}

const Badge = styled.div<StatusBadgeProps>`
  ${({ theme: { colors }, status }) => css`
    border: 1px solid ${colors.backgroundDark};
    background-color: ${status === "Unallocated"
      ? "grey"
      : status === "InProgress"
      ? "darkOrange"
      : status === "Completed"
      ? "green"
      : "gold"};

    color: white;
    display: inline-block;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: 600;
  `};
`;

const StatusBadge = ({
  children,
  status,
}: PropsWithChildren<StatusBadgeProps>) => {
  return <Badge status={status}>{children}</Badge>;
};

export default StatusBadge;
