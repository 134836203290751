export const policy = `
PUNI Truck & Hire ltd is committed to protecting your privacy. This Privacy Policy describes our practices regarding the collection, use, and disclosure of personal information we receive from users of our mobile application.

Collection of Information

We may collect the following types of personal information from you:

Your name and email address
Your device information (e.g., device type, operating system, and version)
Your location information (if you give us permission to access it)
Your usage information (e.g., the features you use, the pages you visit, and the content you view)
We may collect this information in several ways, including:

When you register for an account in our mobile application
When you use our mobile application
When you interact with our mobile application (e.g., by submitting feedback or support requests)
Use of Information

We may use the personal information we collect for the following purposes:

To provide and improve our mobile application
To personalize your experience with our mobile application
To communicate with you about our mobile application
To respond to your feedback and support requests
To comply with applicable laws and regulations
Disclosure of Information

We may disclose your personal information in the following circumstances:

To our service providers and partners who assist us in providing our mobile application (e.g., hosting providers, analytics providers, and payment processors)
To comply with legal obligations (e.g., responding to lawful requests and court orders)
In connection with a merger, acquisition, or sale of assets
We may also share aggregated and anonymized data with third parties for marketing, advertising, research, or other purposes.

Security of Information

We take reasonable measures to protect your personal information from unauthorized access, use, disclosure, or loss. However, no data transmission over the Internet or wireless network can be guaranteed to be 100% secure.

Changes to Privacy Policy

We may update this Privacy Policy from time to time by posting a new version on our website or mobile application. Your continued use of our mobile application after such changes constitutes your acceptance of the revised Privacy Policy.

Contact Us

If you have any questions or concerns about this Privacy Policy or our practices regarding your personal information, please contact us at punitruckhire@gmail.com.

Effective Date

This Privacy Policy is effective as of 14th March 2023`;
