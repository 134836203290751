import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme: { colors, spacing } }) => css`
    background-color: ${colors.background};
    padding: ${spacing.s3};
    border-radius: 4px;
    margin-bottom: 8px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p:last-of-type {
      flex: 1;
    }
  `};
`;

export const Heading = styled.p`
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
`;

export const ModalHeading = styled.h2`
  ${({ theme: { colors } }) => css`
    color: ${colors.primary};
    margin-bottom: 8px;

    span {
      color: ${colors.primary};
      font-size: 14px;
      font-weight: 400;
    }
  `};
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 12px;
  display: inline-block;
  margin: 0 24px 0 16px;
`;

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const BlockedElement = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSubmit = styled.input`
  ${({ theme: { colors } }) => css`
    margin-top: 24px;
    cursor: pointer;
    display: inline-block;
    border: none;
    min-width: 200px;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    transition: border-color 0.2s ease, background-color 0.2s ease,
      color 0.2s ease;
    border-radius: 12px;
    padding: 15px 24px;
    color: ${colors.background};
    background: ${colors.primary};
  `};
`;

export const ClientName = styled.p`
  font-size: 22px;
  margin: 12px 0 12px 0;
  font-weight: 700;
`;

export const ButtonWrapper = styled.div`
  display: flex;

  > * {
    flex: 1;
  }
`;
