import { useState } from "react";
import { motion } from "framer-motion";
import TextField from "@material-ui/core/TextField";

import useGetApi from "../../../../utils/api/useGetApi";
import {
  Heading,
  Paragraph,
  Wrapper,
  ModalHeading,
  ClientName,
  ButtonWrapper,
  Block,
  BlockedElement,
  StyledSubmit,
} from "../../../../shared/Styles";
import Button from "../../../../shared/Button";
import { useDialogState } from "../../../../shared/Modal";
import Modal from "../../../../shared/Modal";
import { AdminProps } from "../../../../typings";
import axios from "axios";
import useAuthToken from "../../../../utils/api/useAuthToken";
import Error, { StyledError } from "../../../../shared/Error";

const adminsVariants = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Admins = () => {
  const [selectedAdmin, setSelectedAdmin] = useState<AdminProps | null>(null);
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [formError, setFormError] = useState("");

  const { headers } = useAuthToken();

  const removeAdminModal = useDialogState();
  const addAdminModal = useDialogState();

  const {
    data: adminData,
    refetch,
    status,
  } = useGetApi("https://api.punitruckhire.nz/Users/role/Admin");

  function handleSubmit(event: any) {
    axios
      .post(
        `https://api.punitruckhire.nz/Users`,
        { name: adminName, email: adminEmail, role: "Admin" },
        {
          headers,
        }
      )
      .then(() => {
        refetch();

        setTimeout(() => {
          setAdminName("");
          setAdminEmail("");
          addAdminModal.hide();
        }, 3000);
      })
      .catch((error) => setFormError(error.response.data));

    event.preventDefault();
  }

  const handleDelete = (id: string) => {
    axios
      .delete(`https://api.punitruckhire.nz/Users/${id}`, {
        headers,
      })
      .then(() => {
        refetch();
        removeAdminModal.hide();
      })
      .catch(() => {
        console.log("error");
      });
  };

  if (status === "ERROR") <Error />;

  return (
    <>
      <Modal {...removeAdminModal}>
        <ModalHeading>Are you sure you want to remove admin </ModalHeading>
        <ClientName>{selectedAdmin ? selectedAdmin.name : null}</ClientName>
        <ButtonWrapper>
          <Button
            variant="secondary"
            onClick={() => {
              setSelectedAdmin(null);
              removeAdminModal.toggle();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleDelete(selectedAdmin ? selectedAdmin.id : "")}
          >
            Confirm
          </Button>
        </ButtonWrapper>
      </Modal>
      <Modal {...addAdminModal}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <BlockedElement>
            <ModalHeading>Add a new admin</ModalHeading>
            {status === "LOADING" ? (
              <p>Adding the admin...</p>
            ) : (
              <>
                <TextField
                  value={adminName}
                  label="Admin Name"
                  onChange={(e) => {
                    setAdminName(e.target.value);
                  }}
                />
                <br />
                <TextField
                  value={adminEmail}
                  label="Admin Email"
                  onChange={(e) => {
                    setAdminEmail(e.target.value);
                  }}
                />
                {formError ? <StyledError>{formError}</StyledError> : null}

                <StyledSubmit type="submit" value="Submit" />
              </>
            )}
          </BlockedElement>
        </form>
      </Modal>
      <Block>
        <ModalHeading>Admins</ModalHeading>
        <Button variant="primary" onClick={addAdminModal.toggle}>
          Add Admin
        </Button>
      </Block>
      <br />
      {adminData &&
        adminData !== undefined &&
        adminData.map((admin, index) => {
          return (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={adminsVariants}
              transition={{
                delay: 0.3 * index,
                ease: "easeInOut",
              }}
            >
              <Wrapper key={admin.id}>
                <Heading>Name:</Heading>
                <Paragraph>{admin.name}</Paragraph>
                <Heading>Email:</Heading>
                <Paragraph>{admin.email}</Paragraph>
                <Heading>Role:</Heading>
                <Paragraph>{admin.role}</Paragraph>
                <Button
                  variant="primary"
                  onClick={() => {
                    removeAdminModal.toggle();
                    setSelectedAdmin(admin);
                  }}
                >
                  Remove Admin
                </Button>
              </Wrapper>
            </motion.div>
          );
        })}
    </>
  );
};

export default Admins;
