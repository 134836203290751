import { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";

import TextField from "@material-ui/core/TextField";

import Modal from "../../../../shared/Modal";
import { useDialogState } from "../../../../shared/Modal";
import Button from "../../../../shared/Button";
import { DriversProps } from "../../../../typings";
import {
  BlockedElement,
  Block,
  Heading,
  ModalHeading,
  Paragraph,
  StyledSubmit,
  Wrapper,
  ButtonWrapper,
  ClientName,
} from "../../../../shared/Styles";
import useAuthToken from "../../../../utils/api/useAuthToken";
import Error, { StyledError } from "../../../../shared/Error";

const driverVariants = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Drivers = () => {
  const [driverData, setDriverData] = useState<DriversProps[] | any>([]);
  const [status, setStatus] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverEmail, setDriverEmail] = useState("");
  const [selectedDriverID, setSelectedDriverID] = useState("");
  const [formError, setFormError] = useState("");

  const addDriverModal = useDialogState();
  const removeDriverModal = useDialogState();

  const { headers, retreievedToken } = useAuthToken();

  useEffect(() => {
    addDriverModal.hide();
    setFormError("");
    if (retreievedToken && status !== "SUCCESS") {
      axios
        .get(`https://api.punitruckhire.nz/Users/role/Driver`, {
          headers,
        })
        .then((response) => {
          setDriverData(response.data);
          setStatus("SUCCESS");
        })
        .catch(() => console.log("error"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retreievedToken, status]);

  function handleSubmit(event: any) {
    axios
      .post(
        `https://api.punitruckhire.nz/Users`,
        { name: driverName, email: driverEmail, role: "Driver" },
        {
          headers,
        }
      )
      .then(() => {
        setStatus("LOADING");

        setTimeout(() => {
          setDriverName("");
          setDriverEmail("");
          addDriverModal.hide();
        }, 3000);
      })
      .catch((error) => setFormError(error.response.data));

    event.preventDefault();
  }

  const HandleDelete = (id: string) => {
    axios
      .delete(`https://api.punitruckhire.nz/Users/${id}`, {
        headers,
      })
      .then(() => {
        setStatus("LOADING");
        setTimeout(() => {
          removeDriverModal.hide();
        }, 3000);
      })
      .catch(() => {
        setStatus("ERROR");
      });
  };

  const findDriverName = driverData.find(
    (client: any) => client.id === selectedDriverID
  );

  if (status === "ERROR") {
    return <Error />;
  }

  return (
    <>
      <Modal {...removeDriverModal}>
        <ModalHeading>Are you sure you want to remove driver</ModalHeading>
        <ClientName>{findDriverName ? findDriverName.name : null}</ClientName>

        <ButtonWrapper>
          <Button
            variant="secondary"
            onClick={() => {
              setSelectedDriverID("");
              removeDriverModal.toggle();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => HandleDelete(selectedDriverID)}
          >
            Confirm
          </Button>
        </ButtonWrapper>
      </Modal>
      <Modal {...addDriverModal}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <BlockedElement>
            <ModalHeading>Add a new driver</ModalHeading>
            {status === "LOADING" ? (
              <p>Adding the driver...</p>
            ) : (
              <>
                <TextField
                  value={driverName}
                  label="Driver Name"
                  onChange={(e) => {
                    setDriverName(e.target.value);
                  }}
                />
                <br />
                <TextField
                  value={driverEmail}
                  label="Driver Email"
                  onChange={(e) => {
                    setDriverEmail(e.target.value);
                  }}
                />
                {formError ? <StyledError>{formError}</StyledError> : null}

                <StyledSubmit type="submit" value="Submit" />
              </>
            )}
          </BlockedElement>
        </form>
      </Modal>
      <Block>
        <ModalHeading>Drivers</ModalHeading>
        <Button variant="primary" onClick={addDriverModal.toggle}>
          Add Driver
        </Button>
      </Block>

      {driverData &&
        driverData !== undefined &&
        driverData.map((driver: DriversProps, index: number) => {
          return (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={driverVariants}
              transition={{
                delay: 0.15 * index,
                ease: "easeInOut",
              }}
            >
              <Wrapper key={driver.id}>
                <Heading>Name:</Heading>
                <Paragraph>{driver.name}</Paragraph>
                <Heading>Email:</Heading>
                <Paragraph>{driver.email}</Paragraph>
                <Button
                  variant="primary"
                  onClick={() => {
                    removeDriverModal.toggle();
                    setSelectedDriverID(driver.id);
                  }}
                >
                  Remove Driver
                </Button>
              </Wrapper>
            </motion.div>
          );
        })}
    </>
  );
};

export default Drivers;
