import styled, { css } from "styled-components";

const NAVIGATION_ITEMS = ["Orders", "Drivers", "Clients", "Admins"];

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.background};
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  `}
`;

const NavItems = styled.div<{ highlight: any }>`
  ${({ theme: { colors, spacing }, highlight }) => css`
    padding: ${spacing.s2};
    cursor: pointer;
    border-radius: 8px;

    ${highlight
      ? `background: ${colors.backgroundDark}; font-weight: 700;`
      : null}

    &:hover,
    &:active {
      font-weight: 700;
      background-color: ${colors.backgroundDark};
    }
  `};
`;

interface SideNavigationProps {
  setSelectedTab: Function;
  selectedTab: any;
}

const SideNavigation = ({
  setSelectedTab,
  selectedTab,
}: SideNavigationProps) => {
  return (
    <Wrapper>
      {NAVIGATION_ITEMS.map((item) => {
        return (
          <NavItems
            highlight={selectedTab === item}
            onClick={() => setSelectedTab(item)}
          >
            {item}
          </NavItems>
        );
      })}
    </Wrapper>
  );
};

export default SideNavigation;
