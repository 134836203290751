import styled, { css } from "styled-components";

const Grid = styled.div`
  ${({ theme: { media, spacing, breakpoints } }) => css`
    box-sizing: border-box;
    max-width: ${breakpoints.largeDesktop}px;
    padding: 0 ${spacing.s2};

    margin: 0 auto;

    ${media.up("mobile")} {
      padding: 0 ${spacing.s3};
    }

    ${media.up("desktop")} {
      padding: 0 ${spacing.s4};
    }

    ${media.up("largeDesktop")} {
      padding: 0 56px;
    }
  `}
`;

export default Grid;
