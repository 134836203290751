import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../../components/Header";
import Jobs from "./components/Orders";
import { Container, Grid, GridColumn, Row } from "../../shared/Grid";
// import styled, { css } from "styled-components";
import SideNavigation from "./components/SideNavigation";
import Admins from "./components/Admins";
import Clients from "./components/Clients";
import Drivers from "./components/Drivers";

const LoggedIn = () => {
  const { user } = useAuth0();
  const [selectedTab, setSelectedTab] = useState("Jobs");

  function renderedComponent() {
    switch (selectedTab) {
      case "Jobs":
        return <Jobs />;
      case "Drivers":
        return <Drivers />;
      case "Clients":
        return <Clients />;
      case "Admins":
        return <Admins />;
      default:
        return <Jobs />;
    }
  }

  return (
    <>
      <Header />
      <Container variant="full-width">
        <Grid>
          <Row>
            <GridColumn mobile={2}>
              {user ? <h2>{user.name}</h2> : null}
            </GridColumn>
          </Row>
        </Grid>
      </Container>
      <Container variant="full-width">
        <Grid>
          <Row>
            <GridColumn mobile={2} desktop={3}>
              <SideNavigation
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
              />
            </GridColumn>
            <GridColumn mobile={2} desktop={9}>
              {renderedComponent()}
            </GridColumn>
          </Row>
        </Grid>
      </Container>
    </>
  );
};

export default LoggedIn;
