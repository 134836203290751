import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";

import TextField from "@material-ui/core/TextField";

import Modal from "../../../../shared/Modal";
import { useDialogState } from "../../../../shared/Modal";
import Error, { StyledError } from "../../../../shared/Error";
import Button from "../../../../shared/Button";
import { DriversProps } from "../../../../typings";
import useAuthToken from "../../../../utils/api/useAuthToken";
import {
  BlockedElement,
  Block,
  Heading,
  ModalHeading,
  Paragraph,
  StyledSubmit,
  Wrapper,
  ClientName,
  ButtonWrapper,
} from "../../../../shared/Styles";

const clientsVariants = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Clients = () => {
  const [clientData, setClientData] = useState<DriversProps[] | any>([]);
  const [status, setStatus] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [selectedClientID, setSelectedClientID] = useState("");
  const [formError, setFormError] = useState("");

  const addClientModal = useDialogState();
  const removeClientModal = useDialogState();

  const { headers, retreievedToken } = useAuthToken();

  useEffect(() => {
    addClientModal.hide();
    setFormError("");
    if (retreievedToken && status !== "SUCCESS") {
      axios
        .get(`https://api.punitruckhire.nz/Users/role/Client`, {
          headers,
        })
        .then((response) => {
          setClientData(response.data);
          setStatus("SUCCESS");
        })
        .catch(() => console.log("error"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retreievedToken, status]);

  function handleSubmit(event: any) {
    axios
      .post(
        `https://api.punitruckhire.nz/Users`,
        { name: clientName, email: clientEmail, role: "Client" },
        {
          headers,
        }
      )
      .then(() => {
        setStatus("LOADING");

        setTimeout(() => {
          setClientName("");
          setClientEmail("");
          addClientModal.hide();
        }, 3000);
      })
      .catch((error) => setFormError(error.response.data));

    event.preventDefault();
  }

  const handleDelete = (id: string) => {
    axios
      .delete(`https://api.punitruckhire.nz/Users/${id}`, {
        headers,
      })
      .then(() => {
        setStatus("LOADING");
        removeClientModal.hide();
      })
      .catch(() => {
        console.log("error");
      });
  };

  if (status === "ERROR") {
    return <Error />;
  }

  const findClientName = clientData.find(
    (client: any) => client.id === selectedClientID
  );

  return (
    <>
      <Modal {...removeClientModal}>
        <ModalHeading>Are you sure you want to remove client </ModalHeading>
        <ClientName>{findClientName ? findClientName.name : null}</ClientName>
        <ButtonWrapper>
          <Button
            variant="secondary"
            onClick={() => {
              setSelectedClientID("");
              removeClientModal.toggle();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleDelete(selectedClientID)}
          >
            Confirm
          </Button>
        </ButtonWrapper>
      </Modal>
      <Modal {...addClientModal}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <BlockedElement>
            <ModalHeading>Add a new client</ModalHeading>
            {status === "LOADING" ? (
              <p>Adding the client...</p>
            ) : (
              <>
                <TextField
                  value={clientName}
                  label="Client Name"
                  onChange={(e) => {
                    setClientName(e.target.value);
                  }}
                />
                <br />
                <TextField
                  value={clientEmail}
                  label="Client Email"
                  onChange={(e) => {
                    setClientEmail(e.target.value);
                  }}
                />
                {formError ? <StyledError>{formError}</StyledError> : null}
                <StyledSubmit type="submit" value="Submit" />
              </>
            )}
          </BlockedElement>
        </form>
      </Modal>
      <Block>
        <ModalHeading>Clients</ModalHeading>
        <Button variant="primary" onClick={addClientModal.toggle}>
          Add Client
        </Button>
      </Block>

      {clientData &&
        clientData !== undefined &&
        clientData.map((client: DriversProps, index: number) => {
          return (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={clientsVariants}
              transition={{
                delay: 0.15 * index,
                ease: "easeInOut",
              }}
            >
              <Wrapper key={client.id}>
                <Heading>Name:</Heading>
                <Paragraph>{client.name}</Paragraph>
                <Heading>Email:</Heading>
                <Paragraph>{client.email}</Paragraph>
                <Button
                  variant="primary"
                  onClick={() => {
                    removeClientModal.toggle();
                    setSelectedClientID(client.id);
                  }}
                >
                  Remove Client
                </Button>
              </Wrapper>
            </motion.div>
          );
        })}
    </>
  );
};

export default Clients;
