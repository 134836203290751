import { useState } from "react";
import Select from "react-select";
import axios from "axios";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

import Modal from "../../../../../../shared/Modal";
import {
  BlockedElement,
  ModalHeading,
  StyledSubmit,
} from "../../../../../../shared/Styles";
import Button from "../../../../../../shared/Button";
import { StyledError } from "../../../../../../shared/Error";
import useAuthToken from "../../../../../../utils/api/useAuthToken";
import {
  jobTypeOption,
  truckTypeOptions,
  truckTypeValues,
} from "../../orderOptions";
import {
  ModifiedOptions,
  MultiplyArray,
} from "../../../../../../utils/baseUtils";

const Flex = styled.div`
  display: flex;
  margin-bottom: 8px;

  &:first-child {
    flex: 1;
  }
`;

const FlexedSelect = styled(Select)`
  flex: 1;
  margin-right: 8px;
`;

interface AddNewOrderProps {
  modal: any;
  clientData: ClientTypes[];
  setStatus: Function;
}

const AddNewOrder = ({ modal, clientData, setStatus }: AddNewOrderProps) => {
  const { headers } = useAuthToken();

  const [jobType, setJobType] = useState<any>("");
  const [pickUpAddress, setPickUpAddress] = useState("");
  const [deliveryAddress, setdeliveryAddress] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [clientId, setClientId] = useState<any>("");
  const [truckTypeValue, setTruckTypeValue] = useState<any>(1);
  const [truckType1, setTruckType1] = useState<any>([]);
  const [truckType2, setTruckType2] = useState<any>([]);
  const [truckType3, setTruckType3] = useState<any>([]);
  const [truckType4, setTruckType4] = useState<any>([]);
  const [formStatus, setFormStatus] = useState("");
  const [startDate, setStartDate] = useState("");

  const modifiedOptions = ModifiedOptions(clientData);

  function renderTruckType(truckType: any, setTruckType: any) {
    return (
      <Flex>
        <FlexedSelect
          value={truckType[0]}
          onChange={(selectedOption) => {
            setTruckType([selectedOption]);
          }}
          options={truckTypeOptions}
          placeholder="Truck type"
        />
        <Select
          value={truckType.length > 1 && truckType[truckType.length - 1]}
          onChange={(selectedOption) =>
            setTruckType([...truckType, selectedOption])
          }
          options={truckTypeValues}
          placeholder="Truck values"
        />
      </Flex>
    );
  }

  const handleAddNewOrder = (e: any) => {
    if (!jobType || !truckType1.length) {
      setFormStatus("Please fill out all the fields in the form");
    }
    let jobsArray = [];
    const startDateUtc = new Date(startDate).toISOString();

    const regularBody = {
      type: jobType.value,
      pickUpAddress,
      deliveryAddress,
      additionalDetails,
      clientId,
      startDate: startDateUtc,
    };

    if (truckType1.length && truckTypeValue > 0) {
      const body = [
        {
          ...regularBody,
          truckType: truckType1[0].value,
        },
      ];

      const truckType1Array = MultiplyArray(
        body,
        truckType1[truckType1.length - 1].value
      );
      jobsArray.push(...truckType1Array);
    }

    if (truckTypeValue > 1) {
      const body = [
        {
          ...regularBody,
          truckType: truckType2[0].value,
        },
      ];

      const truckType2Array = MultiplyArray(
        body,
        truckType2[truckType2.length - 1].value
      );
      jobsArray.push(...truckType2Array);
    }

    if (truckTypeValue > 2) {
      const body = [
        {
          ...regularBody,
          truckType: truckType3[0].value,
        },
      ];

      const truckType3Array = MultiplyArray(
        body,
        truckType3[truckType3.length - 1].value
      );
      jobsArray.push(...truckType3Array);
    }

    if (truckTypeValue > 3) {
      const body = [
        {
          ...regularBody,
          truckType: truckType4[0].value,
        },
      ];

      const truckType4Array = MultiplyArray(
        body,
        truckType4[truckType4.length - 1].value
      );
      jobsArray.push(...truckType4Array);
    }

    axios
      .post(
        `https://api.punitruckhire.nz/Orders`,
        {
          jobs: jobsArray,
          addditionalDetails: "",
        },
        { headers }
      )
      .then(() => {
        setStatus("LOADING");
        setTimeout(() => {
          modal.hide();
        }, 3000);
      })
      .catch((error) => console.log("error"));

    e.preventDefault();
  };

  return (
    <Modal {...modal}>
      <ModalHeading>Add new order</ModalHeading>
      <form onSubmit={(e) => handleAddNewOrder(e)}>
        <BlockedElement>
          <Select
            value={jobType}
            onChange={(selectedOption) => setJobType(selectedOption)}
            options={jobTypeOption}
            placeholder="Pick a job type"
          />
          <br />
          <Select
            value={selectedClient}
            onChange={(selectedOption) => {
              setSelectedClient(selectedOption);
              setClientId(selectedOption.id);
            }}
            options={modifiedOptions}
            placeholder="Pick a client"
          />
          <br />

          {renderTruckType(truckType1, setTruckType1)}
          {truckTypeValue > 1 && renderTruckType(truckType2, setTruckType2)}
          {truckTypeValue > 2 && renderTruckType(truckType3, setTruckType3)}
          {truckTypeValue > 3 && renderTruckType(truckType4, setTruckType4)}

          {((truckTypeValue === 1 && truckType1.length > 1) ||
            (truckTypeValue === 2 && truckType2.length > 1) ||
            (truckTypeValue === 3 && truckType3.length > 1)) && (
            <Button
              variant="secondary"
              onClick={() => setTruckTypeValue(truckTypeValue + 1)}
            >
              Add more trucks
            </Button>
          )}
          <TextField
            label="Pick up address"
            value={pickUpAddress}
            onChange={(e) => {
              setPickUpAddress(e.target.value);
            }}
          />
          <br />

          <TextField
            label="Delivery address"
            value={deliveryAddress}
            onChange={(e) => {
              setdeliveryAddress(e.target.value);
            }}
          />
          <br />

          <TextField
            id="datetime-local"
            label="Start date"
            type="datetime-local"
            defaultValue={startDate}
            value={startDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setStartDate(e.target.value)}
          />

          <br />

          <TextField
            label="Additional details"
            value={additionalDetails}
            onChange={(e) => {
              setAdditionalDetails(e.target.value);
            }}
          />
          <br />
          {formStatus ? <StyledError>{formStatus}</StyledError> : null}
          <StyledSubmit type="submit" value="Submit" />
        </BlockedElement>
      </form>
    </Modal>
  );
};

export default AddNewOrder;
