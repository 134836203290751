import React from "react";
import styled from "styled-components";

export type ContainerVariant = "full-width";

export interface ContainerProps {
  variant: ContainerVariant;
}

const BaseStyle = styled.div<ContainerProps>``;

const FullWithContainer = styled(BaseStyle)`
  width: 100%;
`;

export const ContainerVariantMap = {
  "full-width": FullWithContainer,
};

const Container = ({
  variant,
  children,
  ...rest
}: React.PropsWithChildren<ContainerProps>) => {
  const StyledContainer =
    ContainerVariantMap[variant] || ContainerVariantMap["full-width"];

  return (
    <StyledContainer variant={variant} {...rest}>
      {children}
    </StyledContainer>
  );
};

export default Container;
