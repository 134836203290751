import axios from "axios";

import Modal from "../../../../../../shared/Modal";
import Button from "../../../../../../shared/Button";
import { ModalHeading, ButtonWrapper } from "../../../../../../shared/Styles";
import useAuthToken from "../../../../../../utils/api/useAuthToken";

interface DeleteJobProps {
  modal: any;
  selectedJobId: number;
  setSelectedJobId: Function;
  setStatus: Function;
}
const DeleteJob = ({
  modal,
  selectedJobId,
  setSelectedJobId,
  setStatus,
}: DeleteJobProps) => {
  const { headers, retreievedToken } = useAuthToken();

  function handleDeleteJob() {
    if (headers && retreievedToken) {
      axios
        .delete(`https://api.punitruckhire.nz/Jobs/${selectedJobId}`, {
          headers,
        })
        .then(() => {
          setStatus("LOADING");
          modal.hide();
        })
        .catch(() => {
          console.log("error");
        });
    }
  }

  return (
    <Modal {...modal}>
      <ModalHeading>Are you sure you want to remove this job?</ModalHeading>
      <ButtonWrapper>
        <Button
          variant="secondary"
          onClick={() => {
            setSelectedJobId(0);
            modal.toggle();
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={handleDeleteJob}>
          Confirm
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default DeleteJob;
