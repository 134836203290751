import React from "react";
import styled, { css } from "styled-components";
import Grid from "../Grid";

export type MobileVariant = 1 | 2;
export type TabletVariant = 1 | 2 | 3 | 4 | 5 | 6;
export type DesktopVariant = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface GridColumnProps {
  mobile: MobileVariant;
  tablet?: TabletVariant;
  desktop?: DesktopVariant;
  mobileOffset?: MobileVariant;
  tabletOffset?: TabletVariant;
  desktopOffset?: DesktopVariant;
}

const BaseStyle = styled.div<GridColumnProps>`
  ${({ theme: { media, spacing } }) => css`
    padding: ${spacing.s1};

    ${Grid} {
      padding: 0;
    }

    ${media.up("tablet")} {
      padding: 12px;
    }
  `}
`;

const GridColumnStyle = styled(BaseStyle)`
  ${({
    theme: { media },
    mobile,
    tablet,
    desktop,
    mobileOffset,
    tabletOffset,
    desktopOffset,
  }) => css`
    width: calc(${mobile} / 2 * 100%);

    ${media.up("tablet")} {
      width: ${tablet
        ? `calc(${tablet} / 6 * 100%)`
        : `calc(${mobile * 3} / 6 * 100%)`};
    }

    ${media.up("desktop")} {
      width: ${desktop
        ? `calc(${desktop} / 12 * 100%)`
        : `calc(${mobile * 6} / 12 * 100%)`};

      ${desktopOffset &&
      css`
        margin-left: calc(${desktopOffset} / 12 * 100%);
      `}
    }

    ${media.down("mobile")} {
      ${mobileOffset &&
      css`
        margin-left: calc(${mobileOffset} / 2 * 100%);
      `}
    }

    ${media.between("mobile", "tablet")} {
      ${tabletOffset &&
      css`
        margin-left: calc(${tabletOffset} / 6 * 100%);
      `}
    }
  `}
`;

const GridColumn = ({
  mobile,
  tablet,
  desktop,
  mobileOffset,
  tabletOffset,
  desktopOffset,
  children,
}: React.PropsWithChildren<GridColumnProps>) => {
  return (
    <GridColumnStyle
      mobile={mobile}
      tablet={tablet}
      desktop={desktop}
      mobileOffset={mobileOffset}
      tabletOffset={tabletOffset}
      desktopOffset={desktopOffset}
    >
      {children}
    </GridColumnStyle>
  );
};

export default GridColumn;
