import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Button from "../../shared/Button";
import { checkAdminRole } from "../../utils/baseUtils";
import Loader from "react-loader-spinner";

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  opacity: 0.7;
`;

const Box = styled.div`
  padding: 48px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  display: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Home = () => {
  const { loginWithRedirect, user, isLoading, logout } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (user && !isLoading) {
      return history.push("/loggedin");
    }

    return;
  }, [user, isLoading, history]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader type="Puff" color="#d58c01" height={100} width={100} />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Background />
      <Box>
        <h2>PUNI Truck & Hire</h2>
        {user && !checkAdminRole(user) ? (
          <>
            <br />
            <p>This portal is for admin use only</p>
            <br />
            <Button
              variant="primary"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Log Out
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={() => loginWithRedirect()}>
            Log In
          </Button>
        )}
      </Box>
    </>
  );
};

export default Home;
