import React from "react";
import styled, { css } from "styled-components";

type VariantType = "primary" | "secondary";

type SizeType = "Small" | "Regular";

interface ButtonProps {
  variant: VariantType;
  onClick: () => void;
  size?: SizeType;
}

const StyledButton = styled.button<ButtonProps>`
  display: inline-block;
  border: none;
  min-width: 200px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  transition: border-color 0.2s ease, background-color 0.2s ease,
    color 0.2s ease;
  border-radius: 12px;
  padding: 15px 24px;
  cursor: pointer;
  font-weight: 600;

  ${({ theme: { colors, media }, variant, size }) => css`
    ${size === "Small" &&
    `
      padding: 8px 16px;
      min-width: auto;
      border-radius: 4px;
    `};
    ${variant === "secondary"
      ? `
      color: ${colors.black};
      background: ${colors.background};
      border: 1px solid ${colors.primary};
    `
      : `
    color: ${colors.background};
    background: ${colors.primary};
    border: 1px solid ${colors.background};

    `};

    ${media.down("tablet")} {
      font-size: 14px;
      padding: 8px 12px;
      min-width: auto;
    }
  `};
`;

const Button = ({
  children,
  size = "Regular",
  variant = "primary",
  onClick,
}: React.PropsWithChildren<ButtonProps>) => {
  return (
    <StyledButton variant={variant} onClick={onClick} size={size}>
      {children}
    </StyledButton>
  );
};

export default Button;
