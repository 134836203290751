import { useAuth0 } from "@auth0/auth0-react";
import { Switch, Route } from "react-router-dom";

import Home from "../journeys/Home";
import LoggedIn from "../journeys/LoggedIn";
import { checkAdminRole } from "../utils/baseUtils";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import PrivacyPolicy from "../journeys/PrivacyPolicy";

export const PageRoutes = () => {
  const { user } = useAuth0();

  return (
    <Switch>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      {user !== undefined && checkAdminRole(user) ? (
        <Route path="/loggedin">
          <LoggedIn />
        </Route>
      ) : null}

      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};
