import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import axios from "axios";
import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import FormData from "form-data";

import ImageUpload from "image-upload-react";
import "image-upload-react/dist/index.css";
import fileDownload from "js-file-download";

import Modal from "../../../../../../shared/Modal";
import { ReactComponent as CloseIcon } from "../../../../../../images/close.svg";
import { ReactComponent as DownloadIcon } from "../../../../../../images/download.svg";
import {
  ModalHeading,
  BlockedElement,
  StyledSubmit,
} from "../../../../../../shared/Styles";
import useAuthToken from "../../../../../../utils/api/useAuthToken";
import { jobTypeOption, truckTypeOptions } from "../../orderOptions";

const Error = styled.div`
  padding: 24px;
  color: red;
  border: 1px solid red;
`;

const FormPlaceholder = styled.p`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 6px;
`;

const ImagesWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #c1c1c1;
  padding: 16px;
`;

const ImageFile = styled.div`
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #c1c1c1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
    margin: 0;
    padding: 0;
  }

  > div > svg {
    cursor: pointer;
  }
`;

const ImageUploaderWrapper = styled.div`
  > div > div {
    margin-top: 0;
    ${({ theme: { colors } }) => css`
      background: ${colors.primary} !important;
      border-radius: 8px;
    `};
  }
`;

interface EditJobProps {
  modal: any;
  selectedJobId: number;
  setSelectedJobId: Function;
  setStatus: Function;
  driverData: any;
}

interface EditPayloadResponse {
  type: string;
  pickUpAddress: string;
  deliveryAddress: string;
  additionalDetails: string;
  driverId: string;
  startDate: string;
  completedDate?: string;
  breakTime: number;
  travelTime: number;
}

const EditJob = ({
  modal,
  selectedJobId,
  setSelectedJobId,
  setStatus,
  driverData,
}: EditJobProps) => {
  const { headers, retreievedToken } = useAuthToken();

  const [pickUpAddress, setPickUpAddress] = useState("");
  const [deliveryAddress, setdeliveryAddress] = useState("");
  const [truckType, setTruckType] = useState<any>("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [jobType, setJobType] = useState<any>("");
  const [selectedDriver, setSelectedDriver] = useState<any>("");
  const [driverId, setDriverId] = useState<any>("");
  const [editFormStatus, setEditFormStatus] = useState("");
  const [jobImages, setJobImages] = useState<
    { id: string; name: string }[] | null
  >(null);
  const [imageSrc, setImageSrc] = useState<any>("");
  const [files, setFiles] = useState<any>([]);
  const [startDate, setStartDate] = useState("");
  const [completedDate, setCompletedDate] = useState("");
  const [breakTime, setBreakTime] = useState<string | undefined>(undefined);
  const [travelTime, setTravelTime] = useState<string | undefined>(undefined);

  const modifiedDriverOptions = driverData.map(
    ({ name: value, ...rest }: any) => ({
      value,
      label: value,
      ...rest,
    })
  );

  useEffect(() => {
    setImageSrc("");
    if (headers && retreievedToken) {
      axios
        .get(`https://api.punitruckhire.nz/Jobs/${selectedJobId}`, {
          headers,
        })
        .then((response: any) => {
          setPickUpAddress(response.data.pickUpAddress);
          setdeliveryAddress(response.data.deliveryAddress);
          setAdditionalDetails(response.data.additionalDetails);
          setStartDate(response.data.startDate);
          setCompletedDate(response.data.completedDate);
          setBreakTime(response.data.breakTime);
          setTravelTime(response.data.travelTime);

          setTruckType([
            {
              value: response.data.truckType,
              label: response.data.truckType,
            },
          ]);
          setStatus("SUCCESS");
        })
        .catch(() => {
          console.log("error");
        });
      axios
        .get(`https://api.punitruckhire.nz/Jobs/${selectedJobId}/images`, {
          headers,
        })
        .then((response: any) => {
          setJobImages(response.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobId]);

  function handleEditJob(e: any) {
    const breakTimeNumber = Number(breakTime);
    const travelTimeNumber = Number(travelTime);
    const startDateUtc = new Date(startDate).toISOString();
    const completedDateUtc = new Date(completedDate).toISOString();

    let bodyData: EditPayloadResponse = {
      type: jobType.value,
      pickUpAddress,
      deliveryAddress,
      additionalDetails,
      driverId,
      startDate: startDateUtc,
      breakTime: breakTimeNumber,
      travelTime: travelTimeNumber,
    };

    bodyData = completedDate
      ? { ...bodyData, completedDate: completedDateUtc }
      : bodyData;

    axios
      .put(
        `https://api.punitruckhire.nz/Jobs/${selectedJobId}`,
        { ...bodyData },
        { headers }
      )
      .then(() => {
        if (imageSrc) {
          const data = new FormData();
          data.append("file", files, files.name);

          axios.post(
            `https://api.punitruckhire.nz/Jobs/${selectedJobId}/Images`,
            data,
            {
              headers: {
                ...headers,
                "content-type": `multipart/form-data; boundary=${data._boundary}`,
              },
            }
          );
        }

        setStatus("LOADING");
        setEditFormStatus("SUCCESS");

        setTimeout(() => {
          setAdditionalDetails("");
          setJobType("");
          setPickUpAddress("");
          setdeliveryAddress("");
          setJobType("");
          setSelectedJobId(0);
          setStartDate("");
          setCompletedDate("");
          setBreakTime(undefined);
          setTravelTime(undefined);

          modal.hide();
        }, 3000);
      })
      .catch(() => {
        setEditFormStatus("ERROR");
      });

    e.preventDefault();
  }

  const handleDeleteImage = (id: string) => {
    axios
      .delete(
        `https://api.punitruckhire.nz/Jobs/${selectedJobId}/images/${id}`,
        {
          headers,
        }
      )
      .then(() => {
        const filterImages = jobImages?.filter((image) => image.id !== id);
        if (filterImages) {
          setJobImages(filterImages);
        }
      });
  };
  const handleImageSelect = (e: any) => {
    setFiles(e.target.files[0]);
    setImageSrc(URL.createObjectURL(e.target.files[0]));
  };

  const handleDownloadImage = (id: string) => {
    axios({
      url: `https://api.punitruckhire.nz/Jobs/${selectedJobId}/images/${id}`,
      method: "GET",
      responseType: "blob",
      headers: headers,
    }).then((response: any) => {
      fileDownload(response.data, "image.jpeg");
    });
  };

  return (
    <Modal {...modal}>
      <ModalHeading>Update Job</ModalHeading>
      <form onSubmit={(e) => handleEditJob(e)}>
        <BlockedElement>
          <Select
            value={jobType}
            onChange={(selectedOption) => setJobType(selectedOption)}
            options={jobTypeOption}
            placeholder="Pick a job type"
          />
          <br />
          <Select
            value={selectedDriver}
            onChange={(selectedOption) => {
              setSelectedDriver(selectedOption);
              setDriverId(selectedOption.id);
            }}
            options={modifiedDriverOptions}
            placeholder="Pick a driver"
          />
          <br />
          <Select
            value={truckType}
            onChange={(selectedOption) => setTruckType(selectedOption)}
            options={truckTypeOptions}
            placeholder="Truck Type"
          />
          <br />
          <TextField
            label="Pick up address"
            value={pickUpAddress}
            onChange={(e) => {
              setPickUpAddress(e.target.value);
            }}
          />
          <br />

          <TextField
            label="Delivery up address"
            value={deliveryAddress}
            onChange={(e) => {
              setdeliveryAddress(e.target.value);
            }}
          />
          <br />

          <TextField
            inputProps={{
              step: 0.5,
              max: 3.0,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            defaultValue={travelTime}
            label="Travel time"
            value={travelTime}
            onChange={(e) => setTravelTime(e.target.value)}
          />

          <br />

          <TextField
            inputProps={{
              step: 5,
              max: 60,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            label="Break time"
            defaultValue={breakTime}
            value={breakTime}
            onChange={(e) => setBreakTime(e.target.value)}
          />

          <br />

          {startDate ? (
            <TextField
              id="datetime-local"
              label="Start date"
              type="datetime-local"
              defaultValue={startDate}
              value={startDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setStartDate(e.target.value)}
            />
          ) : null}
          <br />

          {completedDate ? (
            <TextField
              id="datetime-local"
              label="Completed date"
              type="datetime-local"
              defaultValue={completedDate}
              value={completedDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setCompletedDate(e.target.value)}
            />
          ) : null}

          <br />

          <TextField
            label="Additional details"
            value={additionalDetails}
            onChange={(e) => {
              setAdditionalDetails(e.target.value);
            }}
          />
          <br />

          {jobImages && jobImages.length ? (
            <>
              <FormPlaceholder>Images</FormPlaceholder>
              <ImagesWrapper>
                {jobImages.map((image) => (
                  <ImageFile>
                    <p>{image.name}</p>
                    <div>
                      <DownloadIcon
                        onClick={() => handleDownloadImage(image.id)}
                      />
                      <CloseIcon onClick={() => handleDeleteImage(image.id)} />
                    </div>
                  </ImageFile>
                ))}
              </ImagesWrapper>
            </>
          ) : null}

          <br />
          <FormPlaceholder>Add Image</FormPlaceholder>
          <ImageUploaderWrapper>
            <ImageUpload
              handleImageSelect={handleImageSelect}
              imageSrc={imageSrc}
              setImageSrc={setImageSrc}
              style={{
                width: "100%",
                height: 200,
                marginTop: "none",
              }}
            />
          </ImageUploaderWrapper>

          {editFormStatus === "ERROR" ? (
            <Error>Please enter all of the following details</Error>
          ) : null}
          <StyledSubmit type="submit" value="Submit" />
        </BlockedElement>
      </form>
    </Modal>
  );
};

export default EditJob;
