import styled, { css } from "styled-components";

const Row = styled.div`
  ${({ theme: { media, spacing } }) => css`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    margin: -${spacing.s1};

    ${media.up("tablet")} {
      margin: -12px;
    }
  `}
`;

export default Row;
