import axios from "axios";
import { useState, useEffect } from "react";
import useAuthToken from "../useAuthToken";

const useGetApi = (url: string) => {
  const [data, setData] = useState<any[]>([]);
  const [status, setStatus] = useState("");
  const { retreievedToken, headers } = useAuthToken();

  useEffect(() => {
    if (status !== "SUCCESS" && retreievedToken) {
      axios
        .get(url, { headers })
        .then((response: any) => {
          setData(response.data);
          setStatus("SUCCESS");
        })
        .catch(() => setStatus("ERROR"));
    }
  }, [retreievedToken, headers, url, status]);

  function refetch() {
    setStatus("");
  }

  console.log(data);

  return {
    data,
    status,
    refetch,
  };
};

export default useGetApi;
