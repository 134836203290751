import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { Container, Grid, Row, GridColumn } from "../../../../shared/Grid";
import Button from "../../../../shared/Button";
import { useDialogState } from "../../../../shared/Modal";
import { OrdersProp, DriversProps } from "../../../../typings";
import StatusBadge from "../../../../shared/StatusBadge";

import { Block, ModalHeading } from "../../../../shared/Styles";
import DeleteJob from "./components/DeleteJob";
import EditJob from "./components/EditJob";
import { default as AllJobs } from "./components/Jobs";
import AddNewOrder from "./components/AddNewOrder";
import Error from "../../../../shared/Error";

const AlignRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const OrderWrapper = styled.div`
  ${({ theme: { colors, spacing } }) => css`
    padding: ${spacing.s3};
    border-radius: 4px;
    box-shadow: rgb(99 99 99 / 20%) 0px 1px 2px 0px;
    background: #f8f8f8;
    border: 1px solid #e3e3e3;
  `};
`;

const jobsVariants = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Jobs = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [orderData, setOrderData] = useState<OrdersProp | any>([]);
  const [driverData, setDriverData] = useState<DriversProps[] | any>([]);
  const [clientData, setClientData] = useState<DriversProps[] | any>([]);

  const [status, setStatus] = useState("");
  const [selectedJobId, setSelectedJobId] = useState(0);

  const editJobModal = useDialogState();
  const addNewOrderModal = useDialogState();
  const removeJobModal = useDialogState();

  useEffect(() => {
    addNewOrderModal.hide();

    const callApi = async () => {
      try {
        const token = await getAccessTokenSilently();

        await axios
          .get(`https://api.punitruckhire.nz/Orders`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            setOrderData(response.data);
          });

        await axios
          .get(`https://api.punitruckhire.nz/Users/role/Driver`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            setDriverData(response.data);
          });

        await axios
          .get(`https://api.punitruckhire.nz/Users/role/Client`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            setClientData(response.data);
          });

        setStatus("SUCCESS");
      } catch (error) {
        setStatus("ERROR");
      }
    };

    if (status !== "SUCCESS") {
      callApi();
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, getAccessTokenSilently]);

  if (!orderData || !driverData || !clientData) {
    return <Error />;
  }

  return (
    <Container variant="full-width">
      <DeleteJob
        modal={removeJobModal}
        setSelectedJobId={setSelectedJobId}
        selectedJobId={selectedJobId}
        setStatus={setStatus}
      />
      <EditJob
        modal={editJobModal}
        selectedJobId={selectedJobId}
        setStatus={setStatus}
        driverData={driverData}
        setSelectedJobId={setSelectedJobId}
      />
      <AddNewOrder
        modal={addNewOrderModal}
        clientData={clientData}
        setStatus={setStatus}
      />

      <Block>
        <ModalHeading>Orders</ModalHeading>
        <Button variant="primary" onClick={addNewOrderModal.toggle}>
          Add new order
        </Button>
      </Block>
      <Grid>
        <Row>
          {orderData &&
            orderData !== undefined &&
            orderData.map((order: OrdersProp, index: number) => {
              return (
                <GridColumn mobile={2} desktop={12}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={jobsVariants}
                    transition={{
                      delay: 0.3 * index,
                      ease: "easeInOut",
                    }}
                  >
                    <OrderWrapper key={order.id}>
                      <AlignRight>
                        <p>{order.additionalDetails}</p>
                        <StatusBadge status={order.status}>
                          {order.status}
                        </StatusBadge>
                      </AlignRight>

                      <Container variant="full-width">
                        <Grid>
                          <Row>
                            <AllJobs
                              jobsData={order.jobs}
                              editJobModal={editJobModal}
                              removeJobModal={removeJobModal}
                              setSelectedJobId={setSelectedJobId}
                              setStatus={setStatus}
                            />
                          </Row>
                        </Grid>
                      </Container>
                    </OrderWrapper>
                  </motion.div>
                </GridColumn>
              );
            })}
        </Row>
      </Grid>
    </Container>
  );
};

export default Jobs;
