import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import truckTheme from "../base/theme";

const GlobalStyle = createGlobalStyle`  

@font-face {
	font-family: "PingFang Heavy";
    src: url('./Fonts/PingFangHeavy.ttf'); /* IE9 Compat Modes */
    src: url('./Fonts/PingFangHeavy.ttf') format('embedded-opentype'), /* IE6-IE8 */
         url('./Fonts/PingFangHeavy.ttf') format('woff2'), /* Super Modern Browsers */
         url('./Fonts/PingFangHeavy.ttf') format('woff'), /* Pretty Modern Browsers */
         url('./Fonts/PingFangHeavy.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
	font-family: "PingFang Bold";
    src: url('./Fonts/PingFangBold.ttf'); /* IE9 Compat Modes */
    src: url('./Fonts/PingFangBold.ttf') format('embedded-opentype'), /* IE6-IE8 */
         url('./Fonts/PingFangBold.ttf') format('woff2'), /* Super Modern Browsers */
         url('./Fonts/PingFangBold.ttf') format('woff'), /* Pretty Modern Browsers */
         url('./Fonts/PingFangBold.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
	font-family: "PingFang Medium";
    src: url('./Fonts/PingFangMedium.ttf'); /* IE9 Compat Modes */
    src: url('./Fonts/PingFangMedium.ttf') format('embedded-opentype'), /* IE6-IE8 */
         url('./Fonts/PingFangMedium.ttf') format('woff2'), /* Super Modern Browsers */
         url('./Fonts/PingFangMedium.ttf') format('woff'), /* Pretty Modern Browsers */
         url('./Fonts/PingFangMedium.ttf')  format('truetype'), /* Safari, Android, iOS */
}


@font-face {
	font-family: "PingFang Light";
    src: url('./Fonts/PingFangLight.ttf'); /* IE9 Compat Modes */
    src: url('./Fonts/PingFangLight.ttf') format('embedded-opentype'), /* IE6-IE8 */
         url('./Fonts/PingFangLight.ttf') format('woff2'), /* Super Modern Browsers */
         url('./Fonts/PingFangLight.ttf') format('woff'), /* Pretty Modern Browsers */
         url('./Fonts/PingFangLight.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
	font-family: "PingFang Regular";
    src: url('./Fonts/PingFangRegular.ttf'); /* IE9 Compat Modes */
    src: url('./Fonts/PingFangRegular.ttf') format('embedded-opentype'), /* IE6-IE8 */
         url('./Fonts/PingFangRegular.ttf') format('woff2'), /* Super Modern Browsers */
         url('./Fonts/PingFangRegular.ttf') format('woff'), /* Pretty Modern Browsers */
         url('./Fonts/PingFangRegular.ttf')  format('truetype'), /* Safari, Android, iOS */
}
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    outline: none;
  }

  body {
    background-color: #f5f6f7;
    font-family: PingFang Regular, sans-serif;
  }

  body, p, h1, h2, h3, h4, h5 {
    padding: 0;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    text-decoration: none;
  }
`;

type TruckThemeType = typeof truckTheme;

declare module "styled-components" {
  export interface DefaultTheme extends TruckThemeType {}
}

const TruckThemeProvider = ({ children }: React.PropsWithChildren<{}>) => (
  <ThemeProvider theme={truckTheme}>
    <GlobalStyle />
    <div>{children}</div>
  </ThemeProvider>
);

export default TruckThemeProvider;
