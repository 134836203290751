import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { HashRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import TruckThemeProvider from "./utils/Provider";
import { PageRoutes } from "./routes";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-in5oiwob.au.auth0.com"
      clientId="j6sF4oWECgwpBLuusRZAHc11J5WFXjg1"
      redirectUri={window.location.origin}
      audience="https://api.punitruckhireltd.nz/"
    >
      <Router>
        <TruckThemeProvider>
          <PageRoutes />
        </TruckThemeProvider>
      </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
