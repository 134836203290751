import styled, { css } from "styled-components";
import { Container, Grid, GridColumn, Row } from "../../shared/Grid";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../../shared/Button";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { useHistory } from "react-router-dom";
import { checkAdminRole } from "../../utils/baseUtils";

const FullWidthContainer = styled.div`
  ${({ theme: { colors, spacing } }) => css`
    height: 100px;
    background-color: ${colors.primary};
    color: ${colors.background};
    display: flex;
    align-items: center;
    margin-bottom: ${spacing.s3};
  `};
`;

const StyledLogo = styled(Logo)`
  height: 65px;
  cursor: pointer;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Anchor = styled.a`
  margin-right: 24px;
  text-decoration: underline;
  cursor: pointer;
`;

const Header = () => {
  const history = useHistory();
  const { logout, user } = useAuth0();

  return (
    <FullWidthContainer>
      <Container variant="full-width">
        <Grid>
          <Row>
            <GridColumn mobile={2}>
              <FlexWrapper>
                <StyledLogo onClick={() => history.push("/")} />
                <div>
                  <Anchor onClick={() => history.push("/privacy-policy")}>
                    Privacy policy
                  </Anchor>
                  {user !== undefined && checkAdminRole(user) ? (
                    <Button
                      variant="secondary"
                      onClick={() =>
                        logout({ returnTo: window.location.origin })
                      }
                    >
                      Log Out
                    </Button>
                  ) : null}
                </div>
              </FlexWrapper>
            </GridColumn>
          </Row>
        </Grid>
      </Container>
    </FullWidthContainer>
  );
};

export default Header;
