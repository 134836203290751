import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useAuthToken = () => {
  const [retreievedToken, setRetreivedToken] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((token) => setRetreivedToken(token));
  }, [getAccessTokenSilently]);

  return {
    retreievedToken,

    headers: {
      Authorization: `Bearer ${retreievedToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
};

export default useAuthToken;
